import React, { useState } from "react";
import PageTitle from "../../components/shared/PageTitle";
import { useTranslation } from "react-i18next";
import axios from "axios";

export default function Index() {
    const { t } = useTranslation();
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const MAIL_SENDER_URL = "https://mail-sender.semarang10k.xprbs.me/send-mail";
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await axios
            .post(MAIL_SENDER_URL, formData)
            .then((res) => {
                if (res.data.status === "success") {
                    setFormData({
                        name: "",
                        email: "",
                        phone: "",
                        subject: "",
                        message: "",
                    });
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 5000);
                } else {
                    setError(true);
                }
            })
            .catch(() => {
                setSuccess(false);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <div className="text-white md:px-0 px-4 bg-black">
            <div className="relative">
                <PageTitle title={t("contact.title")} />
            </div>
            <div className="top-80 relative bg-black  2xl:px-0 md:px-14 px-0 py-5 pb-96">
                <div className="bg-black min-h-screen container mx-auto w-full font-beVietnam text-white">
                    {isSuccess && (
                        <div className="w-full bg-sky-200 p-4 mb-10 text-sky-800 rounded-lg">
                            <h1>
                                Thank you for reaching out! Your message has been sent successfully.
                                We'll get back to you as soon as possible
                            </h1>
                        </div>
                    )}

                    {isError && (
                        <div className="w-full bg-red-200 p-4 mb-10 text-red-800 rounded-lg">
                            <h1>Something went wrong</h1>
                        </div>
                    )}
                    <div className="flex items-center justify-start h-full">
                        <form className="w-full space-y-8" onSubmit={handleSubmit}>
                            <div className="space-y-6">
                                <label htmlFor="subject">Subject</label>
                                <input
                                    id="subject"
                                    name="subject"
                                    type="text"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    className="bg-neutral-950 block border border-neutral-600 w-full 2xl:py-6 2xl:px-6 p-4 rounded-lg placeholder:text-neutral-600"
                                    placeholder="Cth: Subject"
                                    required
                                />
                            </div>
                            <div className="space-y-6">
                                <label htmlFor="name">Name</label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="bg-neutral-950 block border border-neutral-600 w-full 2xl:py-6 2xl:px-6 p-4 rounded-lg placeholder:text-neutral-600"
                                    placeholder="Cth: John Doe"
                                    required
                                />
                            </div>
                            <div className="inline-flex w-full space-x-10">
                                <div className="w-1/2 space-y-6">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="bg-neutral-950 block border border-neutral-600 w-full 2xl:py-6 2xl:px-6 p-4 rounded-lg placeholder:text-neutral-600"
                                        placeholder="Cth: john@example.com"
                                        required
                                    />
                                </div>
                                <div className="w-1/2 space-y-6">
                                    <label htmlFor="phone">Phone</label>
                                    <input
                                        id="phone"
                                        name="phone"
                                        type="number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        className="bg-neutral-950 block border border-neutral-600 w-full 2xl:py-6 2xl:px-6 p-4 rounded-lg placeholder:text-neutral-600"
                                        placeholder="Cth: 628512345678"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="space-y-6">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    rows={10}
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="bg-neutral-950 block border border-neutral-600 w-full 2xl:py-6 2xl:px-6 p-4 rounded-lg placeholder:text-neutral-600"
                                    placeholder="Cth: Lorem ipsum dolor sit amet"
                                    required
                                ></textarea>
                            </div>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="w-full bg-[#CF0002] py-4 rounded-lg uppercase font-bold"
                            >
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
