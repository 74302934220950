import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Navlink from "./Navlink";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLocale } from "../../redux/Slice";
import { useLocation } from "react-router-dom";

// import { Link } from "react-router-dom";
export default function Navbar() {
    const { t, i18n } = useTranslation();
    const navbarData = t("navbar", { returnObjects: true });
    const [navbar, setNavbar] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;

    const onChangeLocale = (lang) => {
        i18n.changeLanguage(lang);
        dispatch(setLocale(lang));
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY > 0;
            setIsScrolled(scrolled);
        };

        window.addEventListener("scroll", handleScroll);

        // Membersihkan event listener saat komponen dibongkar
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [showChild, setShowChild] = useState(false);

    return (
        <nav
            className={`w-full fixed z-30 top-0 transition-all duration-500 ease-in-out text-white ${
                isScrolled || navbar ? "bg-black/40 backdrop-blur-lg" : "bg-transparent "
            }`}
        >
            <div className="container px-8 mx-auto w-full justify-between lg:w-full md:items-center lg:flex items-center">
                <div>
                    <div className="flex items-center justify-between py-3 md:py-5 lg:block">
                        <a href="/" className="flex items-center">
                            <img src={Logo} alt="" className="w-24 2xl:w-52 md:w-40" />
                        </a>

                        <div className="lg:hidden">
                            <button
                                className={`p-2 ${
                                    isScrolled ? "text-brand-cream" : "text-brand-cream"
                                } rounded-md outline-none focus:border-gray-400 focus:border`}
                                onClick={() => setNavbar(!navbar)}
                            >
                                {navbar ? <XMarkIcon width={24} /> : <Bars3Icon width={24} />}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`flex-1 justify-self-start pb-10 mt-8 2xl:text-base text-sm 
                ${
                    navbar || (pathname === "/registration" && window.innerWidth >= 1024)
                        ? "block"
                        : "hidden"
                }
                ${window.innerWidth >= 1024 ? "lg:block" : "md:block"}`}
                    >
                        <ul
                            className={`items-center justify-center space-y-6 ${
                                isScrolled ? "text-white" : "text-brand-cream"
                            } md:flex md:space-x-14 md:space-y-0 font-redhat antialiased`}
                        >
                            {navbarData.map((value, index) => {
                                return value.isHide === false ? (
                                    <div
                                        key={index}
                                        className="relative"
                                        onClick={() =>
                                            value.child ? setShowChild(!showChild) : null
                                        }
                                    >
                                        <Navlink
                                            label={value.label}
                                            href={value.href}
                                            isButton={value.isButton || false}
                                            child={value.child}
                                        />

                                        {value.child ? (
                                            <div
                                                className={`mt-2 ml-6 ${
                                                    showChild ? "block md:hidden" : "hidden"
                                                }`}
                                            >
                                                <ul className="space-y-3">
                                                    {value.child.map((value, index) => (
                                                        <li key={index + 3}>
                                                            <a href={value.href}>{value.label}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null;
                            })}
                            <li>
                                <button
                                    onClick={() => onChangeLocale("en")}
                                    className={`${i18n.language === "en" && "text-red-500"}`}
                                >
                                    EN
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => onChangeLocale("id")}
                                    className={`${i18n.language === "id" && "text-red-500"}`}
                                >
                                    ID
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}
