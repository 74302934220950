import { ChevronUpIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";

function BackToTop() {
    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    const backToTop = () => {
        document.documentElement.style.scrollBehavior = "smooth";
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <>
            <button
                onClick={backToTop}
                className={` ${
                    showButton ? `inline-block` : `hidden`
                } fixed bottom-[40px] right-[40px] p-3 bg-black/30 backdrop-blur-lg text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-black hover:shadow-lg focus:bg-black focus:shadow-lg focus:outline-none focus:ring-0 active:bg-black active:shadow-lg transition duration-150 ease-in-out`}
            >
                <ChevronUpIcon className="w-10 h-10" />
            </button>
        </>
    );
}

export default BackToTop;
