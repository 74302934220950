import React from "react";
import PageTitle from "../../components/shared/PageTitle";
import "../../registration.css";
import { useTranslation } from "react-i18next";

export default function Invitation() {
    const { i18n } = useTranslation();
    const lang = i18n.language.toUpperCase();
    const jquery = document.createElement("script");
    jquery.src = "https://code.jquery.com/jquery-3.2.1.min.js";
    jquery.async = true;
    document.body.appendChild(jquery);

    const script = document.createElement("script");
    script.src = "https://www.steelytoe.com/static/steelyreg.latest.min.js";
    script.async = true;
    document.body.appendChild(script);
    return (
        <div className="md:px-0 px-4">
            <div className="relative">
                <PageTitle title={"Invitation 2024"} />
            </div>
            <div className="relative top-80 bg-black 2xl:px-0 md:px-14 px-0 pb-96">
                <div className="bg-black min-h-screen h-full container mx-auto w-full font-beVietnam">
                    <link
                        rel="stylesheet"
                        media="all"
                        href="https://www.steelytoe.com/static/steelyreg.latest.css"
                    />
                    <script src="https://code.jquery.com/jquery-3.2.1.min.js"></script>
                    <script src="https://www.steelytoe.com/static/steelyreg.latest.min.js"></script>
                    <div id="steelytoeRegContainer" event-id="478" lang={lang} className="">
                        <h3>Loading Registration Page, please standby ...</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}
