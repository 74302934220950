import React from "react";
import PageTitle from "../../components/shared/PageTitle";
import "../../registration.css";
import { useTranslation } from "react-i18next";

export default function Index() {
    const { t } = useTranslation();
    return (
        <div className="md:px-0 px-4">
            <div className="relative">
                <PageTitle title={"Registration"} />
            </div>
            <div className="relative top-80 bg-black 2xl:px-0 md:px-14 px-0 pb-96">
                <div className="bg-black min-h-screen h-full container mx-auto w-full font-beVietnam">
                    <h1 className="text-white text-2xl">{t("registration.body")}</h1>
                </div>
            </div>
        </div>
    );
}
