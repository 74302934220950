import React from "react";
import ImageAbout from "../../../assets/images/about.png";
import { useTranslation } from "react-i18next";
export default function About() {
    const { t } = useTranslation();
    return (
        <div className="bg-black relative font-beVietnam">
            <div className="grid md:grid-cols-2 min-h-full h-full text-white">
                <div className="place-content-center w-full md:pl-24 md:pr-10 px-10 py-10 uppercase space-y-2">
                    <h1>{t("home.about.title")}</h1>
                    <h2 className="2xl:text-8xl md:text-6xl text-4xl font-bold italic">
                        SEMARANG 10K
                    </h2>
                    <div className="space-y-5 normal-case font-beVietnam font-light md:w-4/5 text-justify">
                        <p>{t("home.about.description1")}</p>
                        <p>{t("home.about.description2")}</p>
                    </div>
                </div>
                <div className="md:place-content-end md:items-end md:justify-end md:flex">
                    <img src={ImageAbout} alt="" className="w-full" />
                </div>
            </div>
        </div>
    );
}
