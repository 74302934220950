import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import BackToTop from "./BackToTop";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLocale } from "../../redux/Slice";

export default function AppWraper({ children }) {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state) => state.locale.locale);

    console.log("bahasa", locale);

    useEffect(() => {
        if (locale && i18n.language !== locale) {
            i18n.changeLanguage(locale);
            dispatch(setLocale(locale));
        } else {
            i18n.changeLanguage(locale);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale, i18n]);
    return (
        <div className="relative bg-black">
            <Navbar />
            <div className="relative top-0 bottom-0 z-20">{children}</div>
            <BackToTop />
            <Footer />
        </div>
    );
}
