import React from "react";
import { useTranslation } from "react-i18next";

export default function Podium() {
    const { t } = useTranslation();
    const PodiumData = t("home.podium", { returnObjects: true });
    return (
        <>
            <div className="w-full bg-categoryPodium relative bg-center bg-cover bg-no-repeat min-h-[50rem]">
                <div className="absolute bg-graphicPrize w-full h-full bg-bottom bg-cover opacity-15"></div>
                <div className="container mx-auto text-center font-beVietnam text-white py-24 space-y-10 relative">
                    <h1 className="uppercase italic font-bold 2xl:text-5xl text-3xl">
                        {t("home.podium.title")}
                    </h1>
                    <div className="w-full flex items-center justify-center">
                        <div className="grid md:grid-cols-3 gap-10 w-4/6">
                            {PodiumData.data.map((value, index) => (
                                <div className=" rounded-lg" key={index}>
                                    <div className="2xl:py-14 md:py-10 py-7 bg-black/30 backdrop-blur-xl text-center rounded-t-lg">
                                        <h1 className="font-bold 2xl:text-2xl text-xl">
                                            {value.title}
                                        </h1>
                                    </div>
                                    <div className="bg-neutral-950 2xl:py-10 md:py-5 px-10 rounded-b-lg font-light h-60">
                                        <p dangerouslySetInnerHTML={{ __html: value.desc }} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
