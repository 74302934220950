import React from "react";
import Hero from "../../components/sections/Home/Hero";
import About from "../../components/sections/Home/About";
import TotalPrize from "../../components/sections/Home/TotalPrize";
import Podium from "../../components/sections/Home/Podium";
import Sponsor from "../../components/sections/Home/Sponsor";

export default function Index() {
    return (
        <>
            <Hero />
            <About />
            <Podium />
            <TotalPrize />
            <Sponsor />
        </>
    );
}
