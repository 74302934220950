import React from "react";
import LeMinerale from "../../../assets/images/sponsor/leminerale.png";
import LogoPemkot from "../../../assets/images/sponsor/pemkot-semarang.png";
import Kompas from "../../../assets/images/sponsor/kompas.png";
import Isoplus from "../../../assets/images/sponsor/isoplus.png";
import Asics from "../../../assets/images/sponsor/asics.png";
import Posh from "../../../assets/images/sponsor/posh.png";
import Generali from "../../../assets/images/sponsor/generali.png";
import EjSport from "../../../assets/images/sponsor/ejsport.png";
import Oatside from "../../../assets/images/sponsor/oatside.png";
import Etawalin from "../../../assets/images/sponsor/etawalin.png";
import Fitbar from "../../../assets/images/sponsor/fitbar.png";

export default function Sponsor() {
    return (
        <div className="bg-white font-beVietnam">
            <div className="relative container w-full mx-auto py-24">
                <div className="grid md:grid-cols-3 uppercase gap-10 py-24">
                    <div className="space-y-4 flex flex-col justify-start items-center">
                        <h2 className="text-xl font-bold">Main Sponsor</h2>
                        <img src={LeMinerale} alt="" className="w-fit" />
                    </div>
                    <div className="space-y-4 flex flex-col justify-start items-center">
                        <h2 className="text-xl font-bold">Hosted By</h2>
                        <img src={LogoPemkot} alt="" className="w-fit" />
                    </div>
                    <div className="space-y-4 flex flex-col justify-start items-center">
                        <h2 className="text-xl font-bold">Organized By</h2>
                        <div className="flex h-full items-center">
                            <img src={Kompas} alt="" className="w-fit h-fit" />
                        </div>
                    </div>
                </div>

                <div className="grid md:grid-cols-2 uppercase gap-10 py-24">
                    <div className="space-y-4 flex flex-col justify-start items-center">
                        <h2 className="text-xl font-bold">OFFICIAL ISOTONIC PARTNER</h2>
                        <div className="flex h-full items-center">
                            <img src={Isoplus} alt="" className="w-fit h-fit" />
                        </div>
                    </div>
                    <div className="space-y-4 flex flex-col justify-start items-center">
                        <h2 className="text-xl font-bold">OFFICIAL ANTIPERSPIRANT PARTNER</h2>
                        <div className="flex h-full items-center">
                            <img src={Posh} alt="" className="w-fit h-fit" />
                        </div>
                    </div>
                </div>

                <div className="text-center">
                    <h2 className="text-xl font-bold">CO SPONSOR</h2>
                </div>
                <div className="flex md:flex-row flex-col items-center justify-center uppercase gap-20 pt-10 pb-24 place-items-center w-full ">
                    <div className=" flex items-center justify-center">
                        <img src={Generali} alt="" className="w-fit h-fit" />
                    </div>
                    <div className=" flex items-center justify-center">
                        <img src={Asics} alt="" className="w-fit h-fit" />
                    </div>
                    <div className=" flex items-center justify-center">
                        <img src={EjSport} alt="" className="w-fit h-fit" />
                    </div>
                    <div className=" flex items-center justify-center">
                        <img src={Oatside} alt="" className="w-fit h-fit" />
                    </div>
                    <div className=" flex items-center justify-center">
                        <img src={Etawalin} alt="" className="w-fit h-fit" />
                    </div>
                    <div className=" flex items-center justify-center">
                        <img src={Fitbar} alt="" className="w-fit h-fit" />
                    </div>
                </div>
            </div>
        </div>
    );
}
