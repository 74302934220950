import { configureStore } from "@reduxjs/toolkit";
import localeReducer from "./Slice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
};
const persistedReducer = persistReducer(persistConfig, localeReducer);

const store = configureStore({
    reducer: {
        locale: persistedReducer,
    },
});

export const persistor = persistStore(store);
export default store;
