import { CalendarIcon, MapPinIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Hero() {
    const { t } = useTranslation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const raceDate = new Date("2024-12-15T00:00:00");
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const distance = raceDate - now;

            if (distance < 0) {
                clearInterval(interval);
                setCountdown({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                });
                return;
            }

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setCountdown({ days, hours, minutes, seconds });
        }, 1000);

        return () => clearInterval(interval);
    }, [raceDate]);
    return (
        <div className="w-full min-h-screen relative">
            <div className="w-full bg-hero h-screen bg-no-repeat bg-cover bg-center text-white">
                <div className="container mx-auto w-full relative 2xl:top-36 md:top-0 top-48 2xl:px-0 md:px-24">
                    <div className="flex md:flex-row flex-col h-[50rem] items-center md:justify-between md:gap-y-0 gap-y-10">
                        <div className="md:w-1/2">
                            <h1 className="2xl:text-8xl text-5xl font-beVietnam font-black italic uppercase leading-none">
                                Step Up <br />
                                Your Limit
                            </h1>
                        </div>
                        <div className="md:w-1/2 md:px-0 px-10">
                            <div className="w-full border border-neutral-600 bg-white/10 backdrop-blur-lg 2xl:py-14 md:py-10 2xl:px-10 md:px-7 py-7 px-5 rounded-lg">
                                <div className="grid lg:grid-cols-7 grid-cols-3 text-center font-beVietnam items-center 2xl:gap-y-10 gap-y-0">
                                    <div className="space-y-2 text-center">
                                        <h2 className="2xl:text-6xl text-2xl font-beVietnam font-black text-center">
                                            {countdown.days}
                                        </h2>
                                        <p className="font-light 2xl:text-base text-xs">DAYS</p>
                                    </div>
                                    <div>
                                        <h2 className="md:text-2xl text-sm font-beVietnam font-black">
                                            ●
                                        </h2>
                                    </div>
                                    <div className="space-y-2 text-center">
                                        <h2 className="2xl:text-6xl text-2xl font-beVietnam font-black text-center">
                                            {countdown.hours}
                                        </h2>
                                        <p className="font-light 2xl:text-base text-xs">HOURS</p>
                                    </div>
                                    <div className="md:block hidden">
                                        <h2 className="md:text-2xl text-sm font-beVietnam font-black">
                                            ●
                                        </h2>
                                    </div>
                                    <div className="space-y-2 text-center">
                                        <h2 className="2xl:text-6xl text-2xl font-beVietnam font-black text-center">
                                            {countdown.minutes}
                                        </h2>
                                        <p className="font-light 2xl:text-base text-xs">MINUTES</p>
                                    </div>
                                    <div>
                                        <h2 className="md:text-2xl text-sm font-beVietnam font-black">
                                            ●
                                        </h2>
                                    </div>
                                    <div className="space-y-2 text-center">
                                        <h2 className="2xl:text-6xl text-2xl font-beVietnam font-black text-center">
                                            {countdown.seconds}
                                        </h2>
                                        <p className="font-light 2xl:text-base text-xs">SECONDS</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 flex items-center justify-center gap-8">
                                <p className="flex items-center gap-x-2 font-beVietnam 2xl:text-base text-sm">
                                    <MapPinIcon className="2xl:w-8 2xl:h-8 w-4 h-4" />
                                    BALAI KOTA SEMARANG
                                </p>
                                <p className="flex items-center gap-x-2 font-beVietnam 2xl:text-base text-sm uppercase">
                                    <CalendarIcon className="2xl:w-8 2xl:h-8 w-4 h-4" />
                                    {t("hero.date")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
