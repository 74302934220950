import React from "react";
import Logo from "../../assets/images/logo.png";

import InstagramIcon from "../../assets/images/icon/instagram.svg";
import FacebookIcon from "../../assets/images/icon/facebook.svg";
import MailIcon from "../../assets/images/icon/mail.svg";
import { useTranslation } from "react-i18next";

export default function Footer({ top = 40 }) {
    const { t } = useTranslation();
    const navbarData = t("navbar", { returnObjects: true });
    return (
        <footer className={`w-full h-full bg-black relative z-30`}>
            <div className="2xl:px-0 md:px-14 px-0">
                <div className="container mx-auto">
                    <div className="flex md:flex-row flex-col items-center justify-between py-20 md:px-0 px-2 gap-y-10">
                        <div>
                            <img src={Logo} alt="" className="w-24 2xl:w-52 md:w-40" />
                        </div>
                        <div className="grid grid-cols-2 md:gap-20">
                            <div className="text-white font-beVietnam space-y-6">
                                <h1 className="font-bold uppercase 2xl:text-lg text-sm">
                                    {t("footer.page")}
                                </h1>
                                <ul className="space-y-2 font-light 2xl:text-base text-xs">
                                    {navbarData.map((value, index) => {
                                        return value.isHide === false ? (
                                            <li key={index}>
                                                <a href={value.href}>{value.label}</a>
                                            </li>
                                        ) : null;
                                    })}
                                </ul>
                            </div>
                            <div className="text-white font-beVietnam space-y-6">
                                <h1 className="font-bold uppercase 2xl:text-lg text-sm">
                                    {t("footer.social")}
                                </h1>
                                <ul className="space-y-4 font-light 2xl:text-base text-xs">
                                    <li>
                                        <a
                                            href="https://instagram.com/smarang10k"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex items-center gap-x-2"
                                        >
                                            <img src={InstagramIcon} alt="" className="w-6" />
                                            semarang10k
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://facebook.com/Semarang10K"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex items-center gap-x-2"
                                        >
                                            <img src={FacebookIcon} alt="" className="w-6" />
                                            Semarang10K
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="mailto:info@semarang10k.com"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex items-center gap-x-2"
                                        >
                                            <img src={MailIcon} alt="" className="w-6" />
                                            info@semarang10k.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
