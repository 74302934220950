import React from "react";
import PageTitle from "../../components/shared/PageTitle";
import { CalendarDaysIcon, MapPinIcon, UsersIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export default function RaceDay() {
    const { t } = useTranslation();
    const data = t("raceday", { returnObjects: true });
    return (
        <div className="text-white md:px-0 px-4">
            <div className="relative">
                <PageTitle title={"Race Day"} />
            </div>
            <div className="top-80 relative bg-black">
                <div className="bg-black min-h-screen container mx-auto w-full font-beVietnam text-white ">
                    <div className="w-full grid grid-cols-3">
                        <div className="space-y-4 inline-flex space-x-3 place-self-center">
                            <CalendarDaysIcon width={24} />
                            <div>
                                <h1 className="text-xl font-bold uppercase">{data[0].title}</h1>
                                <p className="font-light">{data[0].content}</p>
                            </div>
                        </div>

                        <div className="space-y-4 inline-flex space-x-3 place-self-center">
                            <MapPinIcon width={24} />
                            <div>
                                <h1 className="text-xl font-bold uppercase">
                                    Venue Start & Finish
                                </h1>
                                <p className="font-light">BALAI KOTA SEMARANG</p>
                            </div>
                        </div>

                        <div className="space-y-4 inline-flex space-x-3 place-self-center">
                            <UsersIcon width={24} />
                            <div>
                                <h1 className="text-xl font-bold uppercase">{data[2].title}</h1>
                                <p className="font-light">2.500</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-20">
                        <iframe
                            title="Lokasi"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.2293274328863!2d110.40993997618027!3d-6.982242468377256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e708ba87e15e37b%3A0xb6685e7f890af6d9!2sBalai%20Kota%20Semarang!5e0!3m2!1sen!2sid!4v1730135247845!5m2!1sen!2sid"
                            allowfullscreen="true"
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            className="invert w-full h-[40rem] rounded-lg"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}
