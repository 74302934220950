import React from "react";
import { useTranslation } from "react-i18next";
export default function CategoryPodium() {
    const { t } = useTranslation();
    const PodiumData = t("home.podium", { returnObjects: true });
    return (
        <div className="w-full min-h-screen h-full relative 2xl:pb-0 md:pb-0 pb-96">
            <div className="w-full bg-categoryPodium min-h-screen h-full bg-no-repeat bg-cover bg-center text-white md:pb-16">
                <div className="container mx-auto w-full relative 2xl:top-56 md:top-34 top-48 2xl:px-0 md:px-4">
                    <div className="2xl:px-0 md:px-24 px-0">
                        <h1 className="2xl:text-4xl text-2xl font-beVietnam font-bold italic uppercase text-white text-center">
                            {t("home.podium.title")}
                        </h1>
                    </div>
                    <div className="flex w-full items-center justify-center mt-10 text-center font-beVietnam">
                        <div className="grid md:grid-cols-3 gap-10 w-4/6">
                            {PodiumData.data.map((value, index) => (
                                <div className="border border-neutral-800 rounded-lg" key={index}>
                                    <div className="2xl:py-14 py-7 bg-black/30 backdrop-blur-xl text-center rounded-t-lg">
                                        <h1 className="font-bold md:text-2xl text-xl">
                                            {value.title}
                                        </h1>
                                    </div>
                                    <div className="bg-neutral-950 py-5 px-5 rounded-b-lg font-light h-52 2xl:text-base text-sm">
                                        <p dangerouslySetInnerHTML={{ __html: value.desc }} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
