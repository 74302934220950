import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    locale: "id",
};

const localeSlice = createSlice({
    name: "locale",
    initialState,
    reducers: {
        setLocale: (state, action) => {
            state.locale = action.payload;
        },
    },
});

export default localeSlice.reducer;
export const { setLocale } = localeSlice.actions;
