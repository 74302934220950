import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

import {
    BrowserRouter,
    // createBrowserRouter,
    Route,
    // RouterProvider,
    Routes,
} from "react-router-dom";
import AppWraper from "./components/shared/AppWraper";
import IndexHome from "./pages/home/Index";
import IndexContact from "./pages/contact/Index";
import IndexRegistration from "./pages/registration/Index";
import Faq from "./pages/race-information/Faq";
import CategoryPodium from "./pages/race-information/CategoryPodium";
import RulesRegulation from "./pages/race-information/RulesRegulation";
import Prize from "./pages/race-information/Prize";
import RaceDay from "./pages/race-information/RaceDay";
import RaceResult from "./pages/race-information/RaceResult";
import IndexGallelry from "./pages/gallery/Index";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import Invitation from "./pages/registration/Invitation";

// const router = createBrowserRouter([
//     {
//         path: "/",
//         element: <IndexHome />,
//     },
//     {
//         path: "/information/faq",
//         element: <Faq />,
//     },
//     {
//         path: "/information/race-result",
//         element: <RaceResult />,
//     },
//     {
//         path: "/information/rules-regulations",
//         element: <RulesRegulation />,
//     },
//     {
//         path: "/information/prize",
//         element: <Prize />,
//     },
//     {
//         path: "/information/category-podium",
//         element: <CategoryPodium />,
//     },
//     {
//         path: "/information/race-day",
//         element: <RaceDay />,
//     },
//     {
//         path: "/information/race-day",
//         element: <RaceDay />,
//     },
//     {
//         path: "/contact-us",
//         element: <IndexContact />,
//     },
//     {
//         path: "/registration",
//         element: <IndexRegistration />,
//     },
//     {
//         path: "/gallery",
//         element: <IndexGallelry />,
//     },
// ]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <AppWraper>
                        {/* <RouterProvider router={router} /> */}
                        <Routes>
                            <Route path="/" element={<IndexHome />} />
                            <Route path="/information/faq" element={<Faq />} />
                            <Route path="/information/race-result" element={<RaceResult />} />
                            <Route
                                path="/information/rules-regulations"
                                element={<RulesRegulation />}
                            />
                            <Route path="/information/prize" element={<Prize />} />
                            <Route
                                path="/information/category-podium"
                                element={<CategoryPodium />}
                            />
                            <Route path="/information/race-day" element={<RaceDay />} />
                            <Route path="/contact-us" element={<IndexContact />} />
                            <Route path="/registration" element={<IndexRegistration />} />
                            <Route path="/gallery" element={<IndexGallelry />} />
                            <Route path="/invitation-2024" element={<Invitation />} />
                        </Routes>
                    </AppWraper>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
