import React, { useState } from "react";

export default function Navlink({ key, label, href, isButton = false, child = null }) {
    const [showChild, setShowChild] = useState(false);
    return (
        <>
            <div
                key={key}
                className="font-beVietnam relative group"
                onClick={() => (child ? setShowChild(!showChild) : null)}
            >
                {isButton ? (
                    <a
                        href={href}
                        className="px-6 py-3 border border-neutral-600 backdrop-blur-xl bg-white/10 rounded-md"
                    >
                        {label}
                    </a>
                ) : (
                    <a href={href}>{label}</a>
                )}

                {child ? (
                    <>
                        <div
                            className={`absolute mt-4 w-full bg-black/30 backdrop-blur-lg p-4 text-sm rounded-lg ${
                                showChild ? "md:block hidden" : "hidden"
                            }`}
                        >
                            <ul className="space-y-3">
                                {child.map((value, index) => (
                                    <li key={index + 2}>
                                        <a href={value.href}>{value.label}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
}
