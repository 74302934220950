import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export default function Prize() {
    const { t } = useTranslation();
    const PrizeData = t("prize", { returnObjects: true });
    const maxIndex = PrizeData.length - 1;
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? maxIndex : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === maxIndex ? 0 : prevIndex + 1));
    };
    return (
        <div className="bg-black relative">
            <div className="flex mx-auto items-center justify-center z-10 min-h-screen md:pt-10 pt-24 bg-bgPrize bg-no-repeat bg-cover bg-center w-full bg-black">
                <div className="2xl:px-0 md:px-14 px-0 w-full py-4">
                    <div className="w-full font-beVietnam text-white">
                        <div className="container h-full mx-auto w-full">
                            <div className="text-center flex items-center justify-center w-full pt-24 mb-8">
                                <div className="h-full space-y-4">
                                    <h2 className="text-lg font-beVietnam  uppercase text-white">
                                        Prize
                                    </h2>
                                    <h1 className="2xl:text-5xl text-3xl uppercase font-bold max-w-2xl">
                                        {PrizeData[currentIndex].category}
                                    </h1>
                                    <div className="inline-flex space-x-6">
                                        <button
                                            onClick={handlePrev}
                                            className="2xl:p-3 p-1.5 rounded-full bg-black/30 backdrop-blur-lg border border-neutral-700 text-center"
                                        >
                                            <ChevronLeftIcon className="w-6" />
                                        </button>
                                        <button
                                            onClick={handleNext}
                                            className="2xl:p-3 p-1.5 rounded-full bg-black/30 backdrop-blur-lg border border-neutral-700 text-center"
                                        >
                                            <ChevronRightIcon className="w-6" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {PrizeData[currentIndex].data.length > 3 ? (
                                <div className="flex items-center justify-center mx-auto flex-col px-3">
                                    <div className="grid md:grid-cols-3 w-full md:gap-10 gap-2">
                                        {PrizeData[currentIndex].data
                                            .slice(0, 3)
                                            .map((topThree) => (
                                                <div className="border border-neutral-600 w-full text-center py-6 bg-black/30 backdrop-blur-lg rounded-lg">
                                                    {topThree.id === 1
                                                        ? "🥇"
                                                        : topThree.id === 2
                                                        ? "🥈"
                                                        : topThree.id === 3
                                                        ? "🥉"
                                                        : "🏅"}{" "}
                                                    {new Intl.NumberFormat("id-ID", {
                                                        style: "currency",
                                                        currency: "IDR",
                                                    }).format(topThree.prize)}
                                                </div>
                                            ))}
                                    </div>

                                    <div
                                        className={`mt-5 grid grid-cols-1 md:grid-cols-${
                                            PrizeData[currentIndex].data.slice(3).length
                                        } w-full md:gap-6 gap-2`}
                                    >
                                        {PrizeData[currentIndex].data.slice(3).map((topThree) => (
                                            <div className="border border-neutral-600 w-full text-center px-3 py-6 bg-black/30 backdrop-blur-lg rounded-lg md:text-[11px]">
                                                {topThree.id === 1
                                                    ? "🥇"
                                                    : topThree.id === 2
                                                    ? "🥈"
                                                    : topThree.id === 3
                                                    ? "🥉"
                                                    : "🏅"}{" "}
                                                {new Intl.NumberFormat("id-ID", {
                                                    style: "currency",
                                                    currency: "IDR",
                                                }).format(topThree.prize)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full flex items-center justify-center">
                                    <div className="border border-neutral-600 w-fit text-center px-3 py-6 bg-black/30 backdrop-blur-lg rounded-lg">
                                        {PrizeData[currentIndex].data[0].id === 1
                                            ? "🥇"
                                            : PrizeData[currentIndex].data[0].id === 2
                                            ? "🥈"
                                            : PrizeData[currentIndex].data[0].id === 3
                                            ? "🥉"
                                            : "🏅"}{" "}
                                        {new Intl.NumberFormat("id-ID", {
                                            style: "currency",
                                            currency: "IDR",
                                        }).format(PrizeData[currentIndex].data[0].prize)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="h-full"> */}
            {/* </div> */}
        </div>
    );
}
