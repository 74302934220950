const galleryConfigs = {
    galleryConfig2022: {
        eventName: "SEMARANG-10K-2022",
        photographers: [],
        locations: [
            "RPC",
            "Start",
            "RRI Ahmad Yani",
            "BCA Pemuda",
            "Hotel Getz",
            "IBC Blenduk",
            "Marabunta",
            "Gereja Gedangan",
            "Queen City Pemuda",
            "Hotel Amaris",
            "Sendawa",
            "Lawang Sewu",
            "Jembatan Mberok",
            "Paragon",
            "Start/Finish",
            "Kid Dash",
        ],
    },
    galleryConfig2023: {
        eventName: "SEMARANG 10K 2023",
        photographers: [],
        locations: [
            "Start",
            "Lawang Sewu",
            "RRI Ahmad Yani",
            "Gets Hotel",
            "IBC Blenduk",
            "Marabunta",
            "Jembatan Mberok",
            "Amaris Hotel",
            "Finish",
            "Venue",
        ],
    },
};

export default galleryConfigs;
