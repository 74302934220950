import React from "react";
import { useTranslation } from "react-i18next";

export default function TotalPrize() {
    const { t } = useTranslation();
    return (
        <>
            <div className="container mx-auto text-center font-beVietnam text-white py-80 bg-graphicPrize bg-no-repeat bg-center  max-w-6xl">
                <h1 className="text-2xl uppercase font-bold italic">{t("home.prize.title")}</h1>
                <div className="-rotate-6 mt-4">
                    <h2 className="text-9xl uppercase font-bold italic">286</h2>
                    <h2 className="text-4xl uppercase font-bold italic">{t("home.prize.desc")}</h2>
                </div>
            </div>
        </>
    );
}
