import React, { useState } from "react";
import PageTitle from "../../components/shared/PageTitle";
import { useTranslation } from "react-i18next";

export default function RulesRegulation() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation();
    const RulesRegulationData = t("raceinfo.rr", { returnObjects: true });
    return (
        <div className="text-white md:px-0 px-4 bg-black 2xl:pb-96 md:pb-96 pb-80">
            <div className="relative">
                <PageTitle title={"Rules & Regulations"} />
            </div>
            <div className="relative top-80 bg-black 2xl:px-0 md:px-14 px-0">
                <div className="bg-black min-h-screen container mx-auto w-full font-beVietnam text-white">
                    <div className="flex items-start justify-start flex-col gap-y-10">
                        <p className="text-start md:w-1/2">{t("raceinfo.rrTitle")}</p>
                    </div>
                    <div className="flex md:flex-row flex-col gap-x-24 mt-10">
                        <div className="flex flex-col md:gap-y-10 gap-y-5 mb-10">
                            {RulesRegulationData.map((parent, index) => (
                                <button
                                    onClick={() => setCurrentIndex(index)}
                                    className={`uppercase text-start w-52 text-lg ${
                                        currentIndex === index
                                            ? "text-white border-b border-b-white font-bold"
                                            : "text-neutral-500 font-normal border-none"
                                    }`}
                                >
                                    {parent.title}
                                </button>
                            ))}
                        </div>
                        <div>
                            <ul className="font-light list-decimal list-inside space-y-4 leading-loose">
                                {RulesRegulationData[currentIndex].data.map((value) => (
                                    <li dangerouslySetInnerHTML={{ __html: value.desc }}>
                                        {/* <div  /> */}
                                        {/* {value.desc} */}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
