import React from "react";

export default function PageTitle({ title }) {
    return (
        <div className="bg-black w-full min-h-[20rem] absolute bg-pageTitle bg-contain bg-no-repeat bg-top pt-56">
            <div className="relative">
                <div className="container mx-auto 2xl:px-0 md:px-14 px-0">
                    <h1 className="md:text-4xl text-2xl font-beVietnam font-bold italic uppercase text-white">
                        {title}
                    </h1>
                </div>
            </div>
        </div>
    );
}
