import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translations
import NavbarId from "./data/lang/id/navbar.json";
import NavbarEn from "./data/lang/en/navbar.json";

import HeroId from "./data/lang/id/home/hero.json";
import HeroEn from "./data/lang/en/home/hero.json";

import AboutId from "./data/lang/id/home/about.json";
import AboutEn from "./data/lang/en/home/about.json";
import PodiumHomeId from "./data/lang/id/home/podium-categeory.json";
import PodiumHomeEn from "./data/lang/en/home/podium-categeory.json";
import PrizeHomeId from "./data/lang/id/home/prize.json";
import PrizeHomeEn from "./data/lang/en/home/prize.json";

import RulesRegulationId from "./data/lang/id/race-information/rules-regulation.json";
import RulesRegulationEn from "./data/lang/en/race-information/rules-regulation.json";

import faqId from "./data/lang/id/race-information/faq.json";
import faqEn from "./data/lang/en/race-information/faq.json";

import PrizeId from "./data/lang/id/race-information/prize.json";
import PrizeEn from "./data/lang/en/race-information/prize.json";

import ContactId from "./data/lang/id/contact-us.json";
import ContactEn from "./data/lang/en/contact-us.json";

import RaceDayId from "./data/lang/id/race-information/raceday.json";
import RaceDayEn from "./data/lang/en/race-information/raceday.json";

import RegistrationId from "./data/lang/id/registration.json";
import RegistrationEn from "./data/lang/en/registration.json";

const resources = {
    en: {
        translation: {
            navbar: NavbarEn,
            hero: HeroEn,
            footer: {
                page: "Pages",
                social: "Social Media",
            },
            home: {
                about: AboutEn,
                podium: PodiumHomeEn,
                prize: PrizeHomeEn,
            },
            raceinfo: {
                rrTitle:
                    "All participants are required to read and understand the rules and regulations below before registering for the Semarang 10K.",
                rr: RulesRegulationEn,
            },
            prize: PrizeEn,
            faq: faqEn,
            raceday: RaceDayEn,
            contact: ContactEn,
            registration: RegistrationEn,
        },
    },
    id: {
        translation: {
            navbar: NavbarId,
            hero: HeroId,
            footer: {
                page: "Halaman",
                social: "Media Sosial",
            },
            home: {
                about: AboutId,
                podium: PodiumHomeId,
                prize: PrizeHomeId,
            },
            raceinfo: {
                rrTitle:
                    "Para peserta diwajibkan untuk membaca seluruh peraturan dan ketentuan dibawah ini secara seksama sebelum mendaftarkan diri untuk Semarang 10K.",
                rr: RulesRegulationId,
            },
            prize: PrizeId,
            faq: faqId,
            raceday: RaceDayId,
            contact: ContactId,
            registration: RegistrationId,
        },
    },
};

i18n.use(initReactI18next).init({
    fallbackLng: "id",
    lng: "id",
    interpolation: {
        escapeValue: false,
    },
    resources: resources,
});

export default i18n;
